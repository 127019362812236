import React, { FC } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  line-height: 1.5;
`;

export const Localize: FC<Props> = ({ pageNames, className }) => {
  return (
    <StyledWrapper className={className}>
      {pageNames.map((pageName, index) => (
        <React.Fragment key={index}>
          <span>{pageName}</span> {index !== pageNames.length - 1 && "/ "}
        </React.Fragment>
      ))}
    </StyledWrapper>
  );
};

interface Props {
  pageNames: string[];
  className?: string;
}
