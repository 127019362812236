import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "gatsby-plugin-react-i18next";
import { Icon } from "@iconify/react";
import arrowLeftShort from "@iconify/icons-bi/arrow-left-short";

const StyledWrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;

  svg {
    font-size: 2.5rem;
    margin-right: 10px;
  }
`;

export const BackButton: FC<Props> = ({ to, children, className, state }) => (
  <StyledWrapper to={to} className={className} state={state}>
    <Icon icon={arrowLeftShort} />
    {children}
  </StyledWrapper>
);

interface Props {
  to: string;
  children: string;
  className?: string;
  state?: any;
}
