import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { BlogCard } from "@components/atoms/BlogCard";

const StyledWrapper = styled.section`
  ${stylesWrapper};
`;

const StyledHeadline = styled(SectionHeadline)`
  margin-bottom: 80px;

  ${breakpoints.tabletMax`
    margin-bottom: 60px;
  `};

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `};
`;

const StyledArticlesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: max-content;
  flex-wrap: wrap;

  ${breakpoints.tabletMax`
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  `};
`;

const StyledArticle = styled(BlogCard)`
  width: unset;
  flex: 1;

  ${breakpoints.tabletMax`
    &:last-child article {
     padding-bottom: 0;
    }
  `}
`;

export { StyledWrapper, StyledHeadline, StyledArticlesWrapper, StyledArticle };
