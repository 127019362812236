import React from "react";
import { MayBeInterestedInProps } from "./MayBeInterestedIn.types";
import {
  StyledWrapper,
  StyledArticlesWrapper,
  StyledHeadline,
  StyledArticle,
} from "./MayBeInterestedIn.styles";
import { useTranslation } from "react-i18next";

const MayBeInterestedIn: React.FC<MayBeInterestedInProps> = (props) => {
  const { t } = useTranslation("common");
  return (
    <StyledWrapper {...props}>
      <StyledHeadline
        dangerouslySetInnerHTML={{
          __html: t("may-interest"),
        }}
      />
      <StyledArticlesWrapper>
        {props.articles.map((article, index) => (
          <StyledArticle {...article} key={index} smallerSize />
        ))}
      </StyledArticlesWrapper>
    </StyledWrapper>
  );
};

export default MayBeInterestedIn;
