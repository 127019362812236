import React, { FC } from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Contact } from "@components/molecules/Contact";
import { breakpoints } from "@theme/breakpoints";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { BackButton } from "@components/atoms/BackButton";
import { Dots } from "@components/atoms/Dots";
import { Routes } from "@routes";
import { Localize } from "@components/atoms/Localize";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HtmlParser from "react-html-parser";
import { stripHtml } from "@utils/stripHtml";
import MayBeInterestedIn from "@components/molecules/MayBeInterestedIn";
import { getBlogSlug } from "@utils/getBlogSlug";

const StyledOurProjectHeroWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 40px;

  ${breakpoints.tabletMax`
    padding-bottom: 0;
  `}
`;

const StyledOurProjectHeroInnerWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 220px;

  ${breakpoints.tabletMax`
    max-width: 800px;
    margin-top: 180px;
  `}

  ${breakpoints.phoneMax`
    max-width: 800px;
    margin-top: 140px;
  `}
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: 20px;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  ${breakpoints.tabletMax`
   flex-direction: column;
    margin: 40px auto 0;
  `}

  ${breakpoints.phoneMax`
    margin: 20px auto 0;
  `}
`;

const StyledLeftWrapper = styled.div`
  max-width: 650px;
  flex: 1;

  ${breakpoints.tabletMax`
    width: 100%;
  `}
`;

const StyledTagsWrapper = styled.div`
  margin: 20px -10px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledTag = styled.span`
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.washStrong};
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 100px;
`;

const StyledTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 4.8rem;
  font-weight: 700;

  ${breakpoints.phoneMax`
    font-size: 3.4rem;
    margin: 0 0 20px;
  `}
`;

const StyledShortDescription = styled.p`
  max-width: 530px;
  margin: 0;
  font-size: 2rem;
  ${wysiwygStyles}

  ${breakpoints.tabletMax`
    max-width: 800px;
  `};

  ${breakpoints.phoneMax`
    margin-top: 40px;
    font-size: 1.6rem;
  `};
`;

const StyledImageWrapper = styled.div`
  width: 700px;
  height: 526px;
  position: relative;
  margin-left: 50px;

  ${breakpoints.laptopMax`
    width: 540px;
    height: 430px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    height: 60vw;
    margin-left: 0;
    margin-top: 60px;
    max-height: 500px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 30px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 30px;
  width: 100%;
  height: 100%;
`;

const StyledDots = styled(Dots)`
  position: absolute;
  z-index: -1;
  width: 120%;
  height: 120%;
  border-radius: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${breakpoints.tabletMax`
    display: none;
  `}
`;

const StyledContentWrapper = styled.div`
  max-width: 900px;
  width: 90%;
  margin: 120px auto 0;
  font-size: 2rem;

  * {
    max-width: 800px;
    font-family: "Open Sans", "Helvetica Neue", "Roboto", sans-serif !important;
  }

  img,
  iframe {
    max-width: unset;
    width: 100%;
    border-radius: 40px;
    margin: 60px 0;
  }

  iframe {
    height: 70vw;
    max-height: 450px;
  }

  ${wysiwygStyles}

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-left: 0 !important;
  }

  h2 {
    font-size: 4rem;
    margin: 60px 0 40px;
  }

  h3 {
    font-size: 3.2rem;
    margin: 60px 0 30px;
  }

  h4 {
    font-size: 2.8rem;
    margin: 50px 0 30px;
  }

  h5 {
    font-size: 2.4rem;
    margin: 50px 0 20px;
  }

  h6 {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 40px 0 20px;
  }

  figure {
    margin: 0;
  }

  blockquote {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.6;
    position: relative;
    max-width: unset;
    width: 100%;
    margin: 50px 0;
    padding-left: 40px;

    ::before {
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background: ${({ theme }) => theme.primary};
      border-radius: 2px;
      position: absolute;
      content: "";
    }
  }

  ${breakpoints.phoneMax`
    margin-top: 40px;
    font-size: 1.6rem;

    img, iframe {
      max-width: unset;
      width: 100%;
      border-radius: 20px;
      margin: 30px 0;
    }


    h2 {
      font-size: 3rem;
      margin: 40px 0 20px;
    }

    h3 {
      font-size: 2.6rem;
      margin: 40px 0 20px;
    }

    h4 {
      font-size: 2.4rem;
      margin: 30px 0 10px;
    }

    h5 {
      font-size: 2rem;
      margin: 30px 0 10px;
    }

    h6 {
      font-size: 1.6rem;
      text-transform: uppercase;
      margin: 30px 0 10px;
    }

    blockquote {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.6;
      margin: 30px 0;
      padding-left: 20px;

      ::before {
        width: 2px;
        border-radius: 2px;
      }
    }
  `}
`;

const StyledMayBeInterestedIn = styled(MayBeInterestedIn)`
  margin-top: 160px;

  ${breakpoints.laptopMax`
    margin-top: 120px;
  `}

  ${breakpoints.tabletMax`
    margin-top: 90px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 60px;
  `}
`;

const BlogTemplate: FC<{ pageContext: { id: string }; data: Data }> = ({
  data,
}) => {
  const { t } = useTranslation();
  const {
    title,
    shortDescription,
    featuredImage,
    tags,
    content,
    relatedArticles,
  } = data.strapiBlog || data.alternativeStrapiBlog;

  return (
    <BasicTemplate
      title={title}
      description={stripHtml(shortDescription.data.shortDescription)}
      ogImage={featuredImage.localFile.childImageSharp.ogImage.src}
      renderHero={() => (
        <StyledOurProjectHeroWrapper>
          <StyledOurProjectHeroInnerWrapper>
            <StyledBackButton to={Routes.Blog}>
              {t("common:back-button-blog")}
            </StyledBackButton>
            <Localize pageNames={[t("navigation:list.blog"), title]} />

            <StyledInnerWrapper>
              <StyledLeftWrapper>
                <StyledTagsWrapper>
                  {tags.map(({ name }, index) => (
                    <StyledTag key={index}>{name}</StyledTag>
                  ))}
                </StyledTagsWrapper>
                <StyledTitle>{title}</StyledTitle>
                <StyledShortDescription
                  dangerouslySetInnerHTML={{
                    __html: shortDescription.data.shortDescription,
                  }}
                />
              </StyledLeftWrapper>

              <StyledImageWrapper>
                <StyledImage
                  image={getImage(
                    featuredImage.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt=""
                />
                <StyledDots />
              </StyledImageWrapper>
            </StyledInnerWrapper>
          </StyledOurProjectHeroInnerWrapper>
        </StyledOurProjectHeroWrapper>
      )}
    >
      <StyledContentWrapper>
        {content?.data?.content &&
          HtmlParser(
            content.data.content.replace(
              /\/uploads/gi,
              "https://teamcity.rendpro.com/uploads"
            ),
            {
              preprocessNodes(nodes: []): any {
                const ids: number[] = [];
                const mIds: number[] = [];
                const figures = nodes.filter(({ type, name }, index) => {
                  if (type === "tag" && name === "figure") {
                    ids.push(index);
                    return true;
                  }

                  return false;
                });
                figures.forEach(({ children }: any, index) => {
                  if (
                    children?.some(
                      ({ type, name }: any) =>
                        type === "tag" && name === "oembed"
                    )
                  ) {
                    mIds.push(ids[index]);
                    return true;
                  }

                  return false;
                });

                const newNodes = nodes.map((node: any, index) => {
                  let isFounded = false;

                  mIds.forEach((id) =>
                    id === index ? (isFounded = true) : undefined
                  );

                  if (!isFounded) {
                    return node;
                  }

                  return {
                    ...node,
                    children: node.children.map((node: any) => {
                      if (node.name !== "oembed") {
                        return node;
                      }

                      return {
                        ...node,
                        name: "iframe",
                        attribs: {
                          src: node.attribs.url,
                          ...(node.attribs.url.includes("youtube")
                            ? {
                                frameborder: "0",
                                allow:
                                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                allowfullscreen: true,
                              }
                            : {}),
                        },
                      };
                    }),
                  };
                });

                return newNodes;
              },
            }
          )}
      </StyledContentWrapper>
      {relatedArticles && relatedArticles.length > 0 && (
        <StyledMayBeInterestedIn
          articles={relatedArticles.map((article) => ({
            to: getBlogSlug(article.strapi_id),
            featuredImage:
              article.featuredImage.localFile.childImageSharp.gatsbyImageData,
            title: article.title,
            description: article.shortDescription.data.shortDescription,
            tags: article.tags.map(({ name }) => name),
          }))}
        />
      )}
      <Contact />
    </BasicTemplate>
  );
};

interface Data {
  strapiBlog: StrapiBlog;
  alternativeStrapiBlog: StrapiBlog;
}

interface StrapiBlog {
  title: string;
  shortDescription: {
    data: {
      shortDescription: string;
    };
  };
  content: {
    data: {
      content: string;
    };
  };
  tags: {
    name: string;
  }[];
  featuredImage: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: ImageDataLike;
        ogImage: {
          src: string;
        };
      };
    };
  };
  relatedArticles?: {
    strapi_id: number;
    title: string;
    shortDescription: {
      data: {
        shortDescription: string;
      };
    };
    tags: {
      name: string;
    }[];
    featuredImage: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: ImageDataLike;
        };
      };
    };
    createdAt: string;
  }[];
}

export const query = graphql`
  query ($language: String!, $strapi_id: Int!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiBlog(locale: { eq: $language }, strapi_id: { eq: $strapi_id }) {
      title
      shortDescription {
        data {
          shortDescription
        }
      }
      tags {
        name
      }
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
            ogImage: fixed(quality: 70, toFormat: JPG) {
              src
            }
          }
        }
      }
      content {
        data {
          content
        }
      }

      relatedArticles {
        strapi_id
        title
        shortDescription {
          data {
            shortDescription
          }
        }
        tags {
          name
        }
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED)
            }
          }
        }
        createdAt
      }
    }

    alternativeStrapiBlog: strapiBlog(
      locale: { eq: $language }
      localizations: { data: { elemMatch: { id: { eq: $strapi_id } } } }
    ) {
      title
      shortDescription {
        data {
          shortDescription
        }
      }
      tags {
        name
      }
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
            ogImage: fixed(quality: 70, toFormat: JPG) {
              src
            }
          }
        }
      }
      content {
        data {
          content
        }
      }

      relatedArticles {
        strapi_id
        title
        shortDescription {
          data {
            shortDescription
          }
        }
        tags {
          name
        }
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED)
            }
          }
        }
        createdAt
      }
    }
  }
`;

export default BlogTemplate;
