import React from "react";
import styled, { css } from "styled-components";
import { useIsDarkMode } from "@hooks/useIsDarkMode";

export const Image = styled.div<{ $isDarkMode: boolean }>`
  background: url("/dots.png") repeat;

  ${({ $isDarkMode }) =>
    $isDarkMode &&
    css`
      display: none;
    `}
`;

export const Dots = ({ ...props }) => {
  const isDarkMode = useIsDarkMode();
  return <Image $isDarkMode={isDarkMode} {...props} />;
};
