import React, { FC } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { limitText } from "@utils/limitText";
import { Link } from "gatsby-plugin-react-i18next";
import { cssEaseOutExpo } from "@theme/easing";
import { breakpoints } from "@theme/breakpoints";
import { Routes } from "@routes";

const StyledLink = styled(Link)`
  width: calc(50% - 75px);
  max-width: 580px;
  display: block;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;

  ${breakpoints.tabletMax`
    width: 100%;
    max-width: 700px;
  `}
`;

const StyledWrapper = styled.article`
  width: 100%;

  ${breakpoints.tabletMax`
    padding-bottom: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.washLight};
    
    :last-of-type {
      border-bottom: 0;
    }
  `}
`;

const StyledImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 360px;
  border-radius: 40px;
  transform: translateZ(0);

  ${breakpoints.tabletMax`
    height: 60vw;
    max-height: 450px;
    border-radius: 20px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  transition: transform 1s ${cssEaseOutExpo};
  border-radius: 40px;

  ${StyledLink}:hover & {
    transform: scale(1.1) rotate(2deg);
  }

  ${breakpoints.phoneMax`
    border-radius: 20px;
  `}
`;

const StyledTagsWrapper = styled.div`
  margin: 20px -10px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledTag = styled.span`
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.washStrong};
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 100px;
`;

const StyledTitle = styled.h2<{ $smallerSize: boolean }>`
  line-height: 1.4;
  font-weight: 700;
  margin: 0 0 25px;
  font-size: 3.2rem;

  ${({ $smallerSize }) =>
    $smallerSize &&
    css`
      font-size: 2.4rem;
    `};

  ${StyledLink}:hover & {
    text-decoration: underline;
  }

  ${breakpoints.laptopMax`
    font-size: 2.8rem;
    margin: 0 0 20px;
    
    ${({ $smallerSize }: any) =>
      $smallerSize &&
      css`
        font-size: 2.4rem;
      `};
  `}

  ${breakpoints.phoneMax`
    font-size: 2.4rem;
  `}
`;

const StyledDescription = styled.p<{ $smallerSize: boolean }>`
  font-size: 1.8rem;
  margin: 0;
  line-height: 1.5;

  ${({ $smallerSize }) =>
    $smallerSize &&
    css`
      font-size: 1.6rem;
    `};

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}
`;

export const BlogCard: FC<BlogProps> = ({
  title,
  featuredImage,
  tags,
  description,
  to,
  className,
  smallerSize,
}) => (
  <StyledLink to={`${Routes.Blog}${to}/`} className={className}>
    <StyledWrapper>
      <StyledImageWrapper>
        <StyledImage image={getImage(featuredImage)} alt={title} />
      </StyledImageWrapper>
      <StyledTagsWrapper>
        {tags.map((tag, index) => (
          <StyledTag key={index}>{tag}</StyledTag>
        ))}
      </StyledTagsWrapper>

      <StyledTitle $smallerSize={smallerSize}>{title}</StyledTitle>
      <StyledDescription $smallerSize={smallerSize}>
        {limitText(description)}
      </StyledDescription>
    </StyledWrapper>
  </StyledLink>
);

export interface BlogProps {
  title: string;
  featuredImage: ImageDataLike;
  tags: string[];
  description: string;
  to: string;
  className?: string;
  smallerSize?: boolean;
}
