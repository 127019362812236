export const limitText = (text: string): string => {
  const el =
    typeof document !== "undefined"
      ? document.createElement("div")
      : { innerHTML: "", innerText: "" };
  el.innerHTML = text;

  const limitedText = el.innerText.slice(0, 180);
  const trimmedText = limitedText.trimEnd();

  return `${trimmedText}...`;
};
